<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('productFilter')"
                  >
                    <CIcon v-if="!loading" size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="success" square 
                @click="openModalEvent('create', 'productForm', null, 'Ürün Formu (Yeni Kayıt)')">
                Ürün Ekle
              </CButton>&nbsp;
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="products"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            pagination
            :loading="loading"
            hover
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update', 'productForm', lastItem, 'Ürün Formu ('+lastItem.name+')')"
            v-on:refresh="filterProducts"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('list', 'productDetailList', item, item.name + ': Detay Bilgileri')"> Detay </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('update', 'productForm', item, 'Ürün Formu ('+item.name+')')">Düzenle </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('delete', 'confirm', item, 'Ürün Silme ', item.name+' isimli ürünü silmek üzeresin. Bu işlemi gerçekleştirmek istediğine emin misin? ')">Sil</CDropdownItem>
                </CDropdown>
              </td>
            </template>
            <template #createTime="{item}">
              <td> {{ item.createTime && dateFormat(item.createTime) }}</td>
            </template>
            <template #newFlag="{item}">
              <td>
                <CIcon v-if="item.newFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #activeFlag="{item}">
              <td>
                <CIcon v-if="item.activeFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #clearanceFlag="{item}">
              <td>
                <CIcon v-if="item.clearanceFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #catalogFeedFlag="{item}">
              <td>
                <CIcon v-if="item.catalogFeedFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #productGroup="{item}">
              <td>
                {{ getProductGroups(item.productGroup) }}
              </td>
            </template>
            <template #productDesigner="{item}">
              <td>
                {{ getProductDesigner(item.productDesigner) }}
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <ConfirmModal
      v-if="this.form == 'confirm'"
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :actionType="actionType"
      :loading="loading"
      :data="modalProps"
    />
    <ListModal
      v-else-if="actionType == 'list'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterProducts"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import FilterSidebar from '../components/filterSidebar'
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import ListModal from '../components/listModal.vue'
import { prepareFilterParams } from "@/helpers/custom"

import moment from "moment";
export default {
  name: 'Products',
  components: {FilterSidebar, FormModal, ConfirmModal, ListModal},
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'inventory',
      form: 'productForm',
      modalProps: {},
      modalDesc: '',
      lastItem: {},
      openSidebar: false,
      openModal: false,
      filterForm: 'inventoryLogFilter',
      filterParams: {},
      activePage: 1,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: 'Id', _style: 'font-size:12px'},
        {key: 'name', label: 'Ürün Adı', _style: 'font-size:12px'},
        {key: 'createTime', label: 'Kayıt T.', _style: 'font-size:12px'},
        {key: 'code', label: 'Kod', _style: 'font-size:12px'},
        {key: 'productType', label: 'Ürün_Türü', _style: 'font-size:12px'},
        {key: 'rank', label: 'Rank', _style: 'font-size:12px'},
        {key: 'productGroup', label: 'Grup', _style: 'font-size:12px'},
        {key: 'productDesigner', label: 'Tasarımcı', _style: 'font-size:12px'},
        {key: 'activeFlag', label: 'Aktif', _style: 'font-size:12px'},
        {key: 'newFlag', label: 'Yeni', _style: 'font-size:12px'},
        {key: 'clearanceFlag', label: 'Clerance', _style: 'font-size:12px'},
        {key: 'catalogFeedFlag', label: 'Catalog Feed', _style: 'font-size:12px'},
        {key: 'model', label: 'Model', _style: 'font-size:12px'},
        {key: 'manufacturer', label: 'Üretici', _style: 'font-size:12px'},
        {key: 'width', label: 'Genişlik', _style: 'font-size:12px'},
        {key: 'warehouseLocation', label: 'Raf_Kodu', _style: 'font-size:12px'},
        {key: 'height', label: 'Yükseklik', _style: 'font-size:12px'},
        {key: 'depth', label: 'Derinlik', _style: 'font-size:12px'},
        {key: 'dimensionUOM', label: 'Birim', _style: 'font-size:12px'},
        {key: 'weight', label: 'Ağırlık', _style: 'font-size:12px'},
        {key: 'weightUOM', label: 'Ağr. birim', _style: 'font-size:12px'}
      ],
      
      filters: [{
        type: 'numeric',
        dataIndex: 'id',
        field: "id",
        comparison: 'eq'
      }, {
        type: 'string',
        dataIndex: 'name',
        field: "name",
      }, {
        type: 'string',
        dataIndex: 'code',
        field: "code",
      }, {
        type: 'date',
        dataIndex: 'startTime',
        field: "createTime",
        comparison: 'gt'
      }, {
        type: 'date',
        dataIndex: 'endTime',
        field: "createTime",
        comparison: 'lt'
      }, {
        type: 'list',
        dataIndex: 'productType',
        field: "productType",
      },  {
        type: 'list',
        dataIndex: 'productGroup',
        field: 'productGroup',
      }, {
        type: 'list',
        dataIndex: 'productDesigner',
        field: 'productDesigner',
      }, {
        type: 'boolean',
        dataIndex: 'activeFlag',
        field: 'activeFlag',
        comparison: 'eq'
      }, {
        type: 'boolean',
        dataIndex: 'newFlag',
        field: 'newFlag',
        comparison: 'eq'
      }, {
        type: 'boolean',
        dataIndex: 'clearanceFlag',
        field: 'clearanceFlag',
        comparison: 'eq'
      },{
        type: 'boolean',
        dataIndex: 'categoryFeedFlag',
        field: 'categoryFeedFlag',
        comparison: 'eq'
      }, {
        type: 'boolean',
        dataIndex: 'catalogFeedFlag',
        field: 'catalogFeedFlag',
        comparison: 'eq'
      }, {
        type: 'string',
        dataIndex: 'model',
        field: 'model'
      }, {
        type: 'string',
        dataIndex: 'manufacturer',
        field: 'manufacturer'
      }],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },

  },

  methods: {
    openModalEvent(actionType, form, data, title, desc){
      if(actionType == 'list'){
        this.$store.dispatch('productDescription_list', {pId: data.id})
        this.$store.dispatch('productImage_list', {pId: data.id})
        this.$store.dispatch('productUpsale_list', {pId: data.id})
        this.$store.dispatch('productCrosssale_list', {pId: data.id})
        this.$store.dispatch('productPrice_list', {pId: data.id})
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    getProductGroups(data){
      let item = {}

      if(this.productGroups && this.productGroups.length > 0){
       item = this.productGroups.find( e => e.id == data)
      }

      return item ? item.name : "";
    },

    getProductDesigner(data){
      let item = {}

      if(this.designers && this.designers.length > 0){
       item = this.designers.find( e => e.id == data)
      }

      return item ? item.name : "";
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    rowClicked(item) {
      this.lastItem = item;
    },

    async crud(item, action){
      let params = {
        id: item.id,
        actionType: action
      }
      if(this.form == 'productForm'){
        params.name =  item.name;
        params.code =  item.code;
        params.model =  item.model;
        params.warehouseLocation =  item.warehouseLocation;
        params.manufacturer =  item.manufacturer;
        params.productType =  item.productType;
        params.productDesigner =  item.productDesigner;
        params.activeFlag =  item.activeFlag;
        params.newFlag =  item.newFlag;
        params.clearanceFlag =  item.clearanceFlag;
        params.catalogFlag = item.catalogFeedFlag;

        if(action == 'create'){
          params.productGroup =  item.productGroup;
          params.dimensionUOM =  item.dimensionUOM;
          params.width =  item.width;
          params.height =  item.height;
          params.depth =  item.depth;
          params.weightUOM =  item.weightUOM;
          params.weight =  item.weight;
        }

        await this.$store.dispatch('product_action', params)
      }else if(this.form == 'confirm'){
        await this.$store.dispatch('product_action', params)
      }
      if(this.$store.getters.inventoryStatus.success){
        this.filterProducts(this.filterParams);
        this.openModalEvent() 
      }
    },
    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    },  
    async filterProducts(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('product_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  created() {
    this.filterProducts(this.filterParams);
    this.$store.dispatch('activeProductTypesWoS_list');
    this.$store.dispatch('activeProductGroups_list');
    this.$store.dispatch('activeProduct_list');
    this.$store.dispatch('activeDesigner_list');
    this.$store.dispatch('activeCurrency_list');
    this.$store.dispatch('adminUser_list');
  },
  computed: {
    products: function(){
      return this.$store.getters.products
    },
    productGroups: function(){
        return this.$store.getters.activeProductGroups
    },
    designers: function(){
        return this.$store.getters.activeDesigners
    },
    loading: function(){
      return this.$store.getters.inventoryLoading
    }
  },
}
</script>
<style scoped>
input,
select {
  padding: 0.45em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}

</style>
